import React from "react"

import { connect } from "react-redux"
import { Form, Input, Button, Segment } from "semantic-ui-react"
import { logout } from "../reducers/loginReducer"
import { setField, storeUser } from "../reducers/userReducer"
import { toast } from "react-toastify"
import { isValidFinnishIBAN } from "finnish-bank-utils"
import { withTranslation } from "react-i18next"

class UserForm extends React.Component {
  handleChange = (event) => {
    this.setState({ name: event.target.value })
  }

  doContinue = async (event) => {
    const { t } = this.props
    console.log("Continue")
    event.preventDefault()
    if (
      this.props.user.iban !== undefined &&
      this.props.user.iban !== "" &&
      !isValidFinnishIBAN(this.props.user.iban)
    ) {
      toast(t("incorrectiban"), {
        type: toast.TYPE.WARNING,
      })
      return
    }

    try {
      await this.props.storeUser(this.props.user)
    } catch (e) {
      console.log(e.stack)
      toast(t("failed"), {
        type: toast.TYPE.ERROR,
      })
    }
  }

  render() {
    const { t } = this.props
    return (
      <div>
        <Segment>
          <h2>{t("title")}</h2>
          <p>{t("instruction")}</p>
          <Form onSubmit={this.doContinue}>
            <Form.Group widths="equal">
              <Form.Field
                control={Input}
                label={t("firstname")}
                name="firstname"
                required
                width={8}
                value={this.props.user.firstname}
                onChange={(e) =>
                  this.props.setField("firstname", e.target.value)
                }
              />
              <Form.Field
                control={Input}
                label={t("lastname")}
                name="lastname"
                required
                width={8}
                value={this.props.user.lastname}
                onChange={(e) =>
                  this.props.setField("lastname", e.target.value)
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Input}
                label={t("email")}
                name="email"
                width={16}
                required
                readOnly
                value={this.props.email}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Input}
                label={t("address")}
                name="address"
                width={16}
                value={this.props.user.address}
                onChange={(e) => this.props.setField("address", e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Input}
                label={t("postcode")}
                name="postcode"
                width={6}
                value={this.props.user.postcode}
                onChange={(e) =>
                  this.props.setField("postcode", e.target.value)
                }
              />
              <Form.Field
                control={Input}
                label={t("town")}
                name="town"
                width={10}
                value={this.props.user.town}
                onChange={(e) => this.props.setField("town", e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Input}
                label={t("phone")}
                name="phone"
                type="tel"
                width={16}
                value={this.props.user.phone}
                onChange={(e) => this.props.setField("phone", e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Input}
                label={t("iban")}
                name="iban"
                width={16}
                value={this.props.user.iban}
                onChange={(e) => this.props.setField("iban", e.target.value)}
              />
            </Form.Group>
            <p>{t("ibaninstruction")}</p>
            <Button onClick={this.props.logout}>{t("abort")}</Button>
            <Button
              primary
              type="submit"
              disabled={
                this.props.user.firstname < 2 || this.props.user.lastname < 2
              }
            >
              {t("continue")}
            </Button>
          </Form>
        </Segment>
        <p>{t("information", { organization: this.props.cloudname })}</p>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    cloudname: state.login.nimi,
    user: state.user,
    email: state.login.email,
    userid: state.login.userid,
  }
}

export default withTranslation("userform")(
  connect(mapStateToProps, {
    setField,
    storeUser,
    logout,
  })(UserForm)
)
