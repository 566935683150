import React from "react"

import { connect } from "react-redux"
import { setField } from "../reducers/invoiceReducer"
import UploadControls from "./UploadControls"

import {
  Segment,
  Form,
  Input,
  TextArea,
  Select,
  Divider,
  Header,
} from "semantic-ui-react"

import FileDrop from "./FileDrop"
import FileList from "./FileList"
import { withTranslation } from "react-i18next"

const KULULASKU = 120

const UploadForm = (props) => {
  const { t } = props
  return (
    <Segment>
      <FileDrop />
      <FileList />
      <Divider horizontal>
        <Header as="h4">{t("title")}</Header>
      </Divider>

      <Form>
        <Form.Group widths="equal">
          <Form.Field
            label={t("type")}
            control={Select}
            options={props.options}
            value={props.invoice.path}
            onChange={(e, r) => {
              const selected = props.options.find(
                (element) => element.value === r.value
              )
              console.log(selected)
              props.setField("path", r.value)
              props.setField("pathType", selected.pathtype)
            }}
            required
          />
          <Form.Input
            type="number"
            label={t("euros")}
            value={props.invoice.euro}
            onChange={(e) => props.setField("euro", e.target.value)}
            required
          />
        </Form.Group>
        {props.invoice.pathType !== KULULASKU && (
          <Form.Group widths="equal">
            <Form.Input
              type="date"
              label={t("date")}
              value={props.invoice.date}
              onChange={(e) => props.setField("date", e.target.value)}
              required
            />
            <Form.Input
              type="date"
              label={t("duedate")}
              value={props.invoice.dueDate}
              onChange={(e) => props.setField("dueDate", e.target.value)}
            />
          </Form.Group>
        )}
        <Form.Group>
          <Form.Field
            control={Input}
            label={t("subject")}
            width={16}
            value={props.invoice.title}
            onChange={(e) => props.setField("title", e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Field
            control={TextArea}
            label={t("extra")}
            width={16}
            value={props.invoice.info}
            onChange={(e) => props.setField("info", e.target.value)}
          />
        </Form.Group>
      </Form>
      <UploadControls />
    </Segment>
  )
}

const mapStateToProps = (state) => {
  return {
    paths: state.login.paths,
    invoice: state.invoice,
    options: state.paths.map((item) => ({
      key: item.id,
      text: item.nimi,
      value: item.id,
      pathtype: item.tyyppi,
    })),
  }
}

export default withTranslation("uploadform")(
  connect(mapStateToProps, { setField })(UploadForm)
)
