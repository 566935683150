import axios from "axios"
const baseUrl = "api"

let token

const getInformation = async (cloudId) => {
  try {
    const response = await axios.get(baseUrl + "/start/" + cloudId)
    return response.data
  } catch (error) {
    if (error.response) {
      return { error: error.response.status }
    }
    return { error: true }
  }
}

const askForKey = async (cloudId, email, language) => {
  const path = window.location.pathname
  const pathCloudId = parseInt(path.substring(path.lastIndexOf("/") + 1))
  const response = await axios.post(baseUrl + "/start", {
    cloud: pathCloudId,
    email: email,
    language: language,
  })

  token = response.data.token
  return true
}

const loginGoogle = async (cloudId, googleToken) => {
  const path = window.location.pathname
  const pathCloudId = parseInt(path.substring(path.lastIndexOf("/") + 1))
  const response = await axios.post(baseUrl + "/start/", {
    cloud: pathCloudId,
    googleToken: googleToken,
  })
  console.log("Response " + response)
  token = response.data.token
  console.log("token " + token)
  return response.data
}

const getConfig = () => {
  return { headers: { authorization: `bearer ${token}` } }
}

const login = async (code) => {
  console.log("Login " + code)
  const response = await axios.post(
    baseUrl + "/login",
    { code: code },
    getConfig()
  )
  return response.data
}

const updateUser = async (data) => {
  console.log("SAVE " + JSON.stringify(data))
  await axios.put(baseUrl + "/user", data, getConfig())
}

const logout = async () => {
  await axios.post(baseUrl + "/logout", {}, getConfig())
  token = null
}

export default {
  getInformation,
  askForKey,
  login,
  loginGoogle,
  updateUser,
  getConfig,
  logout,
}
