import React from "react"
import { connect } from "react-redux"
import { remove } from "../reducers/attachmentReducer"
import Moment from "react-moment"
import { Header, Divider, Button, Icon, Table } from "semantic-ui-react"
import { withTranslation } from "react-i18next"

const FileList = (props) => {
  return (
    <div>
      <Divider horizontal>
        <Header as="h4">{props.t("list")}</Header>
      </Divider>
      <Table>
        <Table.Body>
          {props.attachments.map((item) => (
            <Table.Row key={item.id}>
              <Table.Cell>{item.filename}</Table.Cell>
              <Table.Cell>
                {"tositepvm" in item && (
                  <Moment format="DD.MM.YYYY">{item.tositepvm}</Moment>
                )}
              </Table.Cell>
              <Table.Cell>
                {isNaN(item.summa) ? "" : item.summa + "€"}
              </Table.Cell>
              <Table.Cell>
                <Button color="red" onClick={() => props.remove(item)}>
                  <Icon name="trash alternate" />
                  {props.t("delete")}
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    attachments: state.attachments,
  }
}

export default withTranslation("filelist")(
  connect(mapStateToProps, { remove })(FileList)
)
