import React from "react"
import { Button } from "semantic-ui-react"
import { useTranslation } from "react-i18next"

export default function LanguageSelector() {
  const { i18n } = useTranslation()

  return (
    <div style={{ padding: "1em" }}>
      <Button.Group basic floated="right">
        <Button
          onClick={() => i18n.changeLanguage("fi")}
          className={i18n.language === "fi" ? "active" : ""}
        >
          Suomeksi
        </Button>
        <Button
          onClick={() => i18n.changeLanguage("sv")}
          className={i18n.language === "sv" ? "active" : ""}
        >
          På svenska
        </Button>
      </Button.Group>
    </div>
  )
}
