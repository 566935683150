const pathsReducer = (store = null, action) => {
  switch (action.type) {
    case "LOGIN":
    case "GOOGLELOGIN":
      return action.data.paths
    default:
      return store
  }
}

export default pathsReducer
