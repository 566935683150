import { createStore, combineReducers, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"

import loginReducer from "./reducers/loginReducer"
import invoiceReducer from "./reducers/invoiceReducer"
import attachmentReducer from "./reducers/attachmentReducer"
import userReducer from "./reducers/userReducer"
import pathsReducer from "./reducers/pathsReducer"

const reducer = combineReducers({
  login: loginReducer,
  invoice: invoiceReducer,
  attachments: attachmentReducer,
  user: userReducer,
  paths: pathsReducer
})

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)))

export default store
