import React from "react"
import { Step } from "semantic-ui-react"
import { withTranslation } from "react-i18next"

class Steps extends React.Component {
  render() {
    const step = this.props.step
    const { t } = this.props
    return (
      <Step.Group widths={5}>
        <Step active={step === 1}>
          <Step.Title>{t("login")}</Step.Title>
        </Step>
        <Step active={step === 2}>
          <Step.Title>{t("code")}</Step.Title>
        </Step>
        <Step active={step === 3}>
          <Step.Title>{t("mydata")}</Step.Title>
        </Step>
        <Step active={step === 4}>
          <Step.Title>{t("document")}</Step.Title>
        </Step>
        <Step active={step === 5}>
          <Step.Title>{t("ready")}</Step.Title>
        </Step>
      </Step.Group>
    )
  }
}

export default withTranslation("steps")(Steps)
