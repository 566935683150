import React from "react"
import { withTranslation } from "react-i18next"
import { Message } from "semantic-ui-react"

function NotFound(props) {
  const { t } = props
  return (
    <Message negative>
      {props.error === 404 && (
        <Message.Header>
          <p>{t("e404")}</p>
          <p>{t("i404")}</p>
        </Message.Header>
      )}
      {props.error !== 404 && (
        <Message.Header>
          <p>{t("ofo")}</p>
          <p>{t("iofo")}</p>
        </Message.Header>
      )}
    </Message>
  )
}

export default withTranslation("notfound")(NotFound)
