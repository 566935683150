import loginService from "../services/loginService"

const loginReducer = (store = null, action) => {
  switch (action.type) {
    case "INIT":
      return action.data
    case "UPDATE":
      return { ...store, ...action.data }
    case "LOGIN":
      return { ...store, step: 3 }
    case "GOOGLELOGIN":
      return { ...store, step: 3, email: action.data.email }
    case "STORED":
      return { ...store, stored: store.stored + 1 }
    default:
      return store
  }
}

export const initLogin = () => {
  return async (dispatch) => {
    const path = window.location.pathname
    const cloudId = parseInt(path.substring(path.lastIndexOf("/") + 1))
    const information = await loginService.getInformation(cloudId)
    dispatch({
      type: "INIT",
      data: {
        step: "error" in information ? 0 : 1,
        cloudId: cloudId,
        stored: 0,
        ...information,
      },
    })
  }
}

export const askForKey = (cloudId, email, language) => {
  return async (dispatch) => {
    await loginService.askForKey(cloudId, email, language)
    dispatch({
      type: "UPDATE",
      data: { step: 2, email: email },
    })
  }
}

export const login = (code) => {
  return async (dispatch) => {
    const response = await loginService.login(code)
    dispatch({
      type: "LOGIN",
      data: response,
    })
  }
}

export const loginWithGoogle = (cloudId, token) => {
  return async (dispatch) => {
    const response = await loginService.loginGoogle(cloudId, token)
    console.log(response)
    dispatch({
      type: "GOOGLELOGIN",
      data: response,
    })
  }
}

export const loginFail = () => {
  return {
    type: "UPDATE",
    data: { step: 1 },
  }
}

export const logout = () => {
  return async (dispatch) => {
    await loginService.logout()
    dispatch({
      type: "UPDATE",
      data: { step: 5, user: null, email: null },
    })
  }
}

export default loginReducer
