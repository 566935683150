import React, { useState } from "react"

import { connect } from "react-redux"
import { resetInvoice, storeInvoice } from "../reducers/invoiceReducer"
import { logout } from "../reducers/loginReducer"
import Moment from "react-moment"
import { toast } from "react-toastify"
import { BrowserView } from "react-device-detect"

import { Button, Image, Modal, Table } from "semantic-ui-react"
import { withTranslation } from "react-i18next"

import { Document, Page } from "react-pdf"
import { pdfjs } from "react-pdf"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const KULULASKU = 120

const UploadControls = (props) => {
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const { t } = props

  const save = async () => {
    setSaving(true)
    // TODO: Validioinnit
    try {
      await props.storeInvoice(props.invoice, props.attachments)
      toast(t("saved"), { type: toast.TYPE.SUCCESS })
    } catch (e) {
      console.log(e.stack)
      toast(t("failed"), { type: toast.TYPE.ERROR })
    }
    console.log("Eurot " + props.invoice.euro)
    handleClose()
    setSaving(false)
  }

  const saveAndLogout = async () => {
    setSaving(true)
    try {
      await props.storeInvoice(props.invoice, props.attachments)
      props.logout()
      toast(t("saved"), { type: toast.TYPE.SUCCESS })
    } catch (e) {
      console.log(e.stack)
      toast(t("failed"), { type: toast.TYPE.ERROR })
    }
    handleClose()
    setSaving(false)
  }

  const handleOpen = () => {
    const date = new Date(props.invoice.date)
    const minDate = new Date(props.minDate)
    const today = new Date()

    if (props.invoice.pathType !== KULULASKU && date < minDate) {
      toast(t("invalidDate"), {
        type: toast.TYPE.WARNING,
      })
    } else if (props.invoice.pathType !== KULULASKU && date > today) {
      toast(t("future"), {
        type: toast.TYPE.WARNING,
      })
    } else if (props.attachments.length === 0) {
      toast(t("imagefirst"), {
        type: toast.TYPE.WARNING,
      })
    } else {
      setOpen(true)
    }
  }
  const handleClose = () => setOpen(false)

  return (
    <div>
      <Button onClick={props.logout}>{t("finish")}</Button>
      <Button onClick={props.resetInvoice}>{t("reset")}</Button>
      <Modal
        trigger={
          <Button
            primary
            onClick={handleOpen}
            disabled={
              props.invoice.euro === "" ||
              parseFloat(props.invoice.euro) === 0.0 ||
              props.invoice.date === ""
                ? true
                : false
            }
          >
            {t("save")}
          </Button>
        }
        dimmer="blurring"
        open={open}
      >
        <Modal.Header>{t("modaltitle")}</Modal.Header>
        <Modal.Content image>
          <BrowserView>
            {props.type === "image/jpeg" && (
              <Image wrapped size="medium" src={props.src} />
            )}
            {props.type === "application/pdf" && (
              <Document file={props.src}>
                <Page pageIndex={0} scale={0.5} />
              </Document>
            )}
          </BrowserView>
          <Modal.Description>
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{t("type")}</Table.Cell>
                  <Table.Cell>{props.pathname}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t("euros")}</Table.Cell>
                  <Table.Cell>
                    {props.invoice.euro === ""
                      ? "0.00"
                      : parseFloat(props.invoice.euro).toFixed(2)}{" "}
                    €
                  </Table.Cell>
                </Table.Row>
                {props.invoice.pathType !== KULULASKU && (
                  <div>
                    <Table.Row>
                      <Table.Cell>{t("date")}</Table.Cell>
                      <Table.Cell>
                        {props.invoice.date === "" ? (
                          ""
                        ) : (
                          <Moment format="DD.MM.YYYY">
                            {props.invoice.date}
                          </Moment>
                        )}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>{t("duedate")}</Table.Cell>
                      <Table.Cell>
                        {props.invoice.dueDate === "" ? (
                          ""
                        ) : (
                          <Moment format="DD.MM.YYYY">
                            {props.invoice.dueDate}
                          </Moment>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </div>
                )}
                <Table.Row>
                  <Table.Cell>{t("subject")}</Table.Cell>
                  <Table.Cell>{props.invoice.title}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t("extra")}</Table.Cell>
                  <Table.Cell>{props.invoice.info}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleClose}>{t("back")}</Button>
          <Button onClick={save} color="green" disabled={saving === true}>
            {t("confnew")}
          </Button>
          <Button
            onClick={saveAndLogout}
            color="green"
            disabled={saving === true}
          >
            {t("confabort")}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => {
  const count = state.attachments.length
  return {
    pathname: state.paths
      .filter((item) => item.id === state.invoice.path)
      .map((item) => item.nimi),
    invoice: state.invoice,
    src: count > 0 ? state.attachments[count - 1].src : null,
    type: count > 0 ? state.attachments[count - 1].type : null,
    attachments: state.attachments,
    minDate: state.login.minDate,
  }
}

export default withTranslation("uploadcontrols")(
  connect(mapStateToProps, { resetInvoice, storeInvoice, logout })(
    UploadControls
  )
)
