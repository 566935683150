import loginService from "../services/loginService"
import bank from "finnish-bank-utils"

const userReducer = (store = null, action) => {
  switch (action.type) {
    case "LOGIN":
    case "GOOGLELOGIN":
      return {
        ...action.data.user,
        iban: bank.formatFinnishIBAN(action.data.user.iban)
      }
    case "USER":
      return { ...store, ...action.data }
    default:
      return store
  }
}

export const setField = (field, value) => {
  const object = {}
  object[field] = value
  return {
    type: "USER",
    data: object
  }
}

export const storeUser = userData => {
  return async dispatch => {
    await loginService.updateUser(userData)
    dispatch({
      type: "UPDATE",
      data: { step: 4 }
    })
  }
}

export default userReducer
