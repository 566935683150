import React from "react"
import ReactDOM from "react-dom"
import App from "./App"

import store from "./store"
import { Provider } from "react-redux"

import { I18nextProvider } from "react-i18next"
import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import translation_fi from "./translations/fi.json"
import translation_sv from "./translations/sv.json"

import {GoogleOAuthProvider} from "@react-oauth/google"

i18next.use(LanguageDetector).init({
  fallbackLng: "fi",
  resources: {
    fi: translation_fi,
    sv: translation_sv,
  },
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <GoogleOAuthProvider 
          clientId='57290181181-ld16vk67fqrb6f000831j31iluheu1o6.apps.googleusercontent.com'
          onScriptLoadError={() => console.log("Google-kirjautuminen ei käytettävissä")} 
        >
          <App />
        </GoogleOAuthProvider>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)
