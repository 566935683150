import React from "react"

import { connect } from "react-redux"
import { initLogin } from "./reducers/loginReducer"
import { resetInvoice } from "./reducers/invoiceReducer"
import { Container } from "semantic-ui-react"
import { toast } from "react-toastify"
import { BrowserView } from "react-device-detect"

import LanguageSelector from "./components/LanguageSelector"
import LoginForm from "./components/LoginForm"
import CodeForm from "./components/CodeForm"
import UserForm from "./components/UserForm"
import UploadForm from "./components/UploadForm"
import Finished from "./components/Finished"
import Footer from "./components/Footer"

import Steps from "./components/Steps"

import Stating from "./components/Starting"
import NotFound from "./components/NotFound"

import { withTranslation } from "react-i18next"

import "semantic-ui-css/semantic.min.css"
import "react-toastify/dist/ReactToastify.css"
import "./index.css"

toast.configure()

class App extends React.Component {
  componentDidMount = async () => {
    await this.props.initLogin()
    this.props.resetInvoice()
  }
   

  render() {

    const ohje = this.props.login && typeof (this.props.login.ohje) === "string" ? this.props.login.ohje.split("\n") : [];

    document.title = this.props.t("title")
    return (
      <Container>
        <BrowserView>
          <LanguageSelector />
        </BrowserView>
        <h1>{this.props.login ? this.props.login.nimi : ""}</h1>
        <p>{ohje.map((kpl, i) => <div key={i}>{kpl}<br/></div>)}</p>
        <BrowserView>
          {this.props.login && <Steps step={this.props.login.step} />}
        </BrowserView>
        {!this.props.login && <Stating />}
        {this.props.login && this.props.login.error && (
          <NotFound error={this.props.login.error} />
        )}
        {this.props.login && this.props.login.step === 1 && <LoginForm />}
        {this.props.login && this.props.login.step === 2 && <CodeForm />}
        {this.props.login && this.props.login.step === 3 && <UserForm />}
        {this.props.login && this.props.login.step === 4 && <UploadForm />}
        {this.props.login && this.props.login.step === 5 && <Finished />}
        <Footer />
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
  }
}

export default withTranslation("app")(
  connect(mapStateToProps, {
    initLogin,
    resetInvoice,
  })(App)
)
