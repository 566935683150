import React from "react"
import { withTranslation } from "react-i18next"
import { Message, Icon } from "semantic-ui-react"

function Starting(props) {
  const { t } = props
  return (
    <Message icon>
      <Icon name="circle notched" loading />
      <Message.Content>
        <Message.Header>{t("title")}</Message.Header>
        {t("text")}
      </Message.Content>
    </Message>
  )
}

export default withTranslation("starting")(Starting)
