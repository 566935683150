import invoiceService from "../services/invoiceService"
import moment from "moment"

let initPath = 0
let initPathType = 0

const invoiceReducer = (store = null, action) => {
  switch (action.type) {
    case "RESETINVOICE":
    case "STORED":
      return {
        euro: "",
        date: moment().format("YYYY-MM-DD"),
        dueDate: "",
        title: "",
        info: "",
        path: initPath,
        pathType: initPathType,
      }
    case "INVOICE":
      return { ...store, ...action.data }
    case "LOGIN":
    case "GOOGLELOGIN":
      console.log(action.data.paths)
      initPath = action.data.paths.length ? action.data.paths[0].id : 0
      initPathType = action.data.paths.length
        ? action.data.paths[0].tositetyyppi
        : 0
      return { ...store, path: initPath, pathType: initPathType }
    case "ATTACH": {
      console.log("STORE " + store.euro + " KUITTI " + action.data.summa)
      const summa =
        (store.euro === "" ? 0 : parseFloat(store.euro)) +
        ("summa" in action.data ? parseFloat(action.data.summa) : 0)
      const euro = isNaN(summa) ? "" : parseFloat(summa).toFixed(2)
      const date =
        action.data.tositepvm > store.date ? action.data.tositepvm : store.date
      return { ...store, date: date, euro: euro }
    }
    case "REMOVE": {
      if (store.euro === "" || !("summa" in action.data)) return store
      const erotus = parseFloat(store.euro) - parseFloat(action.data.summa)
      const euro = isNaN(erotus) ? "" : parseFloat(erotus).toFixed(2)
      const date =
        action.data.tositepvm > store.date ? action.data.tositepvm : store.date
      return { ...store, euro: euro, date: date }
    }
    default:
      return store
  }
}

export const resetInvoice = () => {
  console.log("Reset invoice")
  return {
    type: "RESETINVOICE",
  }
}

export const setField = (field, value) => {
  const object = {}
  object[field] = value
  console.log("RED " + JSON.stringify(object))
  return {
    type: "INVOICE",
    data: object,
  }
}

export const storeInvoice = (document, attachments) => {
  return async (dispatch) => {
    await invoiceService.saveInvoice(document, attachments)
    dispatch({
      type: "STORED",
      data: {},
    })
  }
}

export default invoiceReducer
