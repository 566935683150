const attachmenetReducer = (store = null, action) => {
  switch (action.type) {
    case "RESETINVOICE":
    case "STORED":
      return []
    case "ATTACH":
      return store.concat(action.data)
    case "REMOVE":
      return store.filter(item => item.id !== action.data.id)
    default:
      return store
  }
}

export const attach = data => {
  console.log("ATTACH")
  console.log(data)
  return {
    type: "ATTACH",
    data: data
  }
}

export const remove = data => {
  console.log("REMOVE")
  console.log(data)
  return {
    type: "REMOVE",
    data: data
  }
}

export default attachmenetReducer
