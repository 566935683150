import React from "react"
import { withTranslation } from "react-i18next"

function Footer(props) {
  const { t } = props
  return (
    <div>
      {t("pre")} <a href="https://kitsas.fi">{t("link")}</a> &copy; Kitsas Oy
      2020
    </div>
  )
}

export default withTranslation("footer")(Footer)
