import React, { useState } from "react"
import { connect } from "react-redux"
import { attach } from "../reducers/attachmentReducer"

import attachementService from "../services/attachementService"

import { useDropzone } from "react-dropzone"
import { MobileOnlyView, BrowserView } from "react-device-detect"
import {
  Segment,
  Button,
  Header,
  Icon,
  Image,
  Dimmer,
  Loader,
  Grid,
} from "semantic-ui-react"

import { toast } from "react-toastify"
import { withTranslation } from "react-i18next"

import { Document, Page } from "react-pdf"
import { pdfjs } from "react-pdf"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const FileDrop = (props) => {
  const [loading, setLoading] = useState(false)
  const { t } = props

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
      reader.onabort = reject

      reader.readAsArrayBuffer(file)
    })
  }

  const onDrop = async (acceptedFiles) => {
    setLoading(true)
    try {
      await acceptedFiles.forEach(async (file) => {
        console.log(file)
        const meta = {
          src: URL.createObjectURL(file),
          filename: file.name,
          type: file.type,
        }
        try {
          const binary = await readFileAsync(file)
          const response = await attachementService.saveAttachement(
            meta,
            binary
          )
          console.log("LISÄTTY " + JSON.stringify(response))
          props.attach({ ...meta, ...response })
          setLoading(false)
        } catch (e) {
          toast(t("failed"), { type: toast.TYPE.ERROR })
          setLoading(false)
        }
      })
    } catch (e) {
      toast(t("failed"), { type: toast.TYPE.ERROR })
      setLoading(false)
    }
  }

  const onDropRejected = (rejectedFiles) => {
    rejectedFiles.forEach((file) => {
      if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
        toast(t("onlyjpgpdf"), {
          type: toast.TYPE.ERROR,
        })
      } else if (file.size > 8388608) {
        toast(t("max8mb"), {
          type: toast.TYPE.ERROR,
        })
      }
    })
    setLoading(false)
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    onDropRejected,
    accept: ["application/pdf", "image/jpeg"],
    maxSize: 8388608,
  })

  return (
    <div>
      <div {...getRootProps()}>
        <input {...getInputProps()} hidden />
        <Segment placeholder style={{ minHeight: "3el" }}>
          {props.src === null ? (
            <div>
              <Header icon textAlign="center">
                <Icon name="picture" />
                <BrowserView>{t("drop")}</BrowserView>
                <MobileOnlyView>{t("camera")}</MobileOnlyView>
              </Header>
            </div>
          ) : props.type === "application/pdf" ? (
            <Document file={props.src}>
              <Page pageIndex={0} />
            </Document>
          ) : (
            <Image src={props.src} centered wrapped size="massive" />
          )}
          <Dimmer active={loading === true}>
            <Loader>{t("loading")}</Loader>
          </Dimmer>
        </Segment>
      </div>
      {props.src && (
        <Grid>
          <Grid.Column textAlign="center">
            <br />
            <Button onClick={open}>{t("select")}</Button>
          </Grid.Column>
        </Grid>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  const count = state.attachments.length
  return {
    src: count > 0 ? state.attachments[count - 1].src : null,
    type: count > 0 ? state.attachments[count - 1].type : null,
    attachments: state.attachments,
    minDate: state.login.minDate,
  }
}

export default withTranslation("filedrop")(
  connect(mapStateToProps, { attach })(FileDrop)
)
