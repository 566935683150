import React, { useState } from "react"

import { connect } from "react-redux"
import { login, loginFail, logout } from "../reducers/loginReducer"

import { Form, Input, Button, Segment } from "semantic-ui-react"
import { toast } from "react-toastify"

import { withTranslation } from "react-i18next"

const LoginForm = (props) => {
  const [code, setCode] = useState("")
  const [tries, setTries] = useState(5)
  const { t } = props

  const doContinue = async (event) => {
    event.preventDefault()
    try {
      await props.login(code)
    } catch (e) {
      console.log(e)
      setTries(tries - 1)

      if (tries > 0) {
        toast(t("incorrect"), { type: toast.TYPE.ERROR })
      } else {
        toast(t("incorrect5"), { type: toast.TYPE.ERROR })
        props.loginFail()
      }
    }
    setCode("")
  }

  return (
    <Segment>
      <h2>{t("confirm")}</h2>
      <p>{t("email", { email: props.email })}</p>
      <Form onSubmit={doContinue}>
        <Form.Group>
          <Form.Field required>
            <label>{t("code")}</label>
            <Input
              type="text"
              name="code"
              maxLength="6"
              pattern="[ACEFGHKLMNPQRSTUXYZ234569]+"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Button onClick={props.logout}>{t("abort")}</Button>
          <Button primary type="submit" disabled={code.length < 6}>
            {t("continue")}
          </Button>
        </Form.Group>
      </Form>
    </Segment>
  )
}
const mapStateToProps = (state) => {
  return {
    email: state.login.email,
  }
}

export default withTranslation("codeform")(
  connect(mapStateToProps, { login, loginFail, logout })(LoginForm)
)
