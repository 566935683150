import React, { useState } from "react"

import { connect } from "react-redux"
import { askForKey, loginWithGoogle } from "../reducers/loginReducer"
import {
  Form,
  Input,
  Button,
  Segment,
  Divider,
  Header,
} from "semantic-ui-react"
import { GoogleLogin } from "@react-oauth/google"

import * as EmailValidator from "email-validator"
import { withTranslation } from "react-i18next"

const CodeForm = (props) => {
  const [email, setEmail] = useState("")
  const { t, i18n } = props

  const doContinue = async (event) => {
    console.log("Continue " + email)
    event.preventDefault()
    try {
      await props.askForKey(props.cloud, email, i18n.language)
    } catch (e) {
      console.log(e)
    }
  }

  const loginGoogle = async (response) => {
    await props.loginWithGoogle(props.cloud, response.credential)
  }

  return (
    <Segment>
      <h2>{t("title")}</h2>
      <p>{t("instruction")}</p>
      <Form onSubmit={doContinue}>
        <Form.Group>
          <Form.Field required width="16">
            <label>{t("email")}</label>
            <Input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Input>
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Button
            primary
            type="submit"
            disabled={!EmailValidator.validate(email)}
          >
            {t("continue")}
          </Button>
        </Form.Group>
      </Form>
      <Divider horizontal>
        <Header as="h4">{t("or")}</Header>
      </Divider>
      <GoogleLogin
        onSuccess={(response) => loginGoogle(response)}
        onError={() => {console.log("Google-kirjautuminen epäonnistui")}}
      />
    </Segment>
  )
}
const mapStateToProps = (state) => {
  return {
    cloud: state.login.cloudId,
  }
}

export default withTranslation("loginform")(
  connect(mapStateToProps, { askForKey, loginWithGoogle })(CodeForm)
)
