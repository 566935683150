import React from "react"
import { Segment, Button } from "semantic-ui-react"
import { connect } from "react-redux"
import { loginFail } from "../reducers/loginReducer"
import { withTranslation } from "react-i18next"

function Finished(props) {
  const { t } = props
  return (
    <Segment>
      <h2>{t("title")}</h2>
      {props.stored === 1 && <p>{t("one")}</p>}
      {props.stored > 1 && <p>{t("many", { count: props.stored })}</p>}
      <p>{t("out")}</p>
      <Button onClick={props.loginFail}>{t("again")}</Button>
    </Segment>
  )
}

const mapStateToProps = (state) => {
  return {
    stored: state.login.stored,
  }
}

export default withTranslation("finished")(
  connect(mapStateToProps, { loginFail })(Finished)
)
